@import url(https://fonts.googleapis.com/css2?family=Fira+Mono&family=Text+Me+One&family=Tourney&family=Megrim&display=swap);
/* ------------------------------- fonts --------------------------------------- */

/* ------------------------------- init --------------------------------------- */
:root {
  --gold: #ffd700;
  --pink: #e417d9;
  --aqua: #01dcff;
  --purp: #7276e8;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: #e417d9 #ffd700;
  scrollbar-color: var(--pink) var(--gold);
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffd700;
  background: var(--gold);
}

*::-webkit-scrollbar-thumb {
  background-color: #e417d9;
  background-color: var(--pink);
}

body * {
  font-family: "Text Me One", sans-serif;
}

/* ------------------------------- navbar --------------------------------------- */
.logo {
  width: 12.25rem;
}

nav {
  z-index: 2;
}

/* ------------------------------- nav-links --------------------------------------- */
.navbar-nav {
  font-size: 1.325rem;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
}

.navbar-nav a {
  text-decoration: none;
}

.navbar-nav a li {
  color: #ffd700 !important;
  color: var(--gold) !important;
  margin-right: 1.5rem;
  border-top: 0.1875rem solid rgba(255, 255, 255, 0);
  transition: 0.3s ease-in-out;
}

.navbar-nav a li.active,
.navbar-nav a li.active:focus {
  border-top: 0.1875rem solid #e417d9;
  border-top: 0.1875rem solid var(--pink);
}

.navbar-nav a li:hover {
  border-top: 0.1875rem solid #e417d9;
  border-top: 0.1875rem solid var(--pink);
  transition: 0.3s ease-in-out;
}

/* ------------------------------- footer --------------------------------------- */
.footer-basic {
  background-color: #212529;
  padding: 1rem 0;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 0.5rem;
}

.footer-basic .social a {
  font-size: 2.5rem;
  text-align: center;
  margin: 0 2rem;
  color: #ffd700;
  color: var(--gold);
  transition: 0.3s ease-in-out;
}

.footer-basic .social a:hover {
  color: #e417d9;
  color: var(--pink);
  transition: 0.3s ease-in-out;
}

.footer-basic .copyright {
  text-align: center;
  font-size: 13px;
  color: rgb(90, 90, 90);
  margin-bottom: 0;
}

/* ------------------------------- header --------------------------------------- */
.header-wraper {
  position: relative;
  background: url(/static/media/gameboy-background.e0792ecd.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 92.9vh;
  min-height: 95vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #ffd700;
  color: var(--gold);
  text-transform: uppercase;
  font-size: 8rem;
  text-shadow: 6px 0 1px rgba(21, 196, 250, 0.5),
    0 6px 1px rgba(253, 22, 168, 0.5);
  font-weight: bolder;
  font-family: "Fira Mono", monospace;
  -webkit-animation: glitch 1s linear infinite;
          animation: glitch 1s linear infinite;
}

.main-info h1:after {
  -webkit-animation: glitchBotom 1.5s linear infinite;
          animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

.main-info h1:before,
.main-info h1:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.main-info h1:before {
  -webkit-animation: glitchTop 1s linear infinite;
          animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

/* ------------------------------- typed text --------------------------------------- */
.typed-text {
  font-size: 3rem;
  color: #fff;
  text-shadow: 3px 0 1px rgba(21, 196, 250, 0.5),
    0 3px 1px rgba(253, 22, 168, 0.5);
  font-weight: bolder;
}

/* ------------------------------- header main contact button --------------------------------------- */
.btn-main-contact {
  border: 1px solid #ffd700;
  border: 1px solid var(--gold);
  text-transform: uppercase;
  border-radius: 4px;
  padding: 1.625rem 1.75rem 1.75rem 1.75rem;
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.46);
  font-weight: bolder;
  text-shadow: 3px 0 1px rgba(21, 196, 250, 0.5),
    0 3px 1px rgba(253, 22, 168, 0.5);
  box-shadow: 3px 0 1px rgba(21, 196, 250, 0.5),
    0 3px 1px rgba(253, 22, 168, 0.5);
  transition: 0.4s ease-in-out;
}

.btn-main-contact:hover {
  text-decoration: none;
  transition: 0.4s ease-in-out;
  color: #ffd700;
  color: var(--gold);
  background-color: rgba(0, 0, 0, 0.624);
  box-shadow: none;
  text-shadow: none;
}

/* ------------------------------- caret --------------------------------------- */
.caret {
  margin-top: 18rem;
  font-size: 4rem;
  -webkit-animation: bounce 2s infinite;
          animation: bounce 2s infinite;
}

/* ------------------------------- particles.js --------------------------------------- */
.tsparticles-canvas-el {
  position: absolute;
  height: 82.25vh !important;
  width: 100vw;
  z-index: 1;
}

/* ------------------------------- about --------------------------------------- */
.jumbo {
  display: flex;
  height: 30vh;
  object-fit: cover;
  justify-content: center;
  align-items: center;
}

.jumbo video {
  position: relative;
  display: flex;
  object-fit: cover;
  height: 100%;
  z-index: 0;
  min-width: -webkit-max-content;
  min-width: max-content;
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}

.jumbo h1 {
  z-index: 2;
  position: absolute;
  text-transform: uppercase;
  font-size: 8rem;
  font-weight: bolder;
  color: #fff;
  background-image: linear-gradient(to right, red, blue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 300%;
  background-position: -100%;
  animation: animatedText 5s infinite alternate-reverse;
  font-family: "Tourney", cursive;
}

.about-container {
  background-image: url(/static/media/about-pattern.b42f11e9.jpg);
  background-position: center;
  background-size: cover;
  min-height: 90.5vh;
  max-width: 100% !important;
  overflow-x: hidden;
}

.kd {
  max-width: 60%;
  -webkit-clip-path: polygon(
    40% 0%,
    60% 0%,
    100% 0%,
    100% 60%,
    60% 100%,
    0% 100%,
    0% 86%,
    0% 40%
  );
  clip-path: polygon(
    40% 0%,
    60% 0%,
    100% 0%,
    100% 60%,
    60% 100%,
    0% 100%,
    0% 86%,
    0% 40%
  );
  margin: 5rem;
}

.kd-info {
  font-size: 1.5rem;
  margin: 5rem;
  color: #fff;
}

.skills {
  margin: 10rem auto;
}

.skills p {
  font-size: 2rem;
  color: #fff;
  border-bottom: 1px solid #ffd700;
  border-bottom: 1px solid var(--gold);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  -webkit-animation: colorChange 5s infinite;
          animation: colorChange 5s infinite;
}

.skills li {
  font-size: 1.3rem;
  color: #fff;
  list-style-type: none;
  text-shadow: 90px 90px 8px #ffd700;
  text-shadow: 90px 90px 8px var(--gold);
  line-height: 2;
}

.skill-group {
  margin: 5rem 0;
}

.skill-group svg {
  color: #ffd700;
  color: var(--gold);
  font-size: 6rem;
  margin-bottom: 2rem;
  -webkit-animation: iconColorChange 5s infinite;
          animation: iconColorChange 5s infinite;
}

/* ------------------------------- projects --------------------------------------- */

.projects-title {
  background-color: #212529;
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 5rem;
  color: #ffd700;
  color: var(--gold);
  -webkit-animation: animatedText2 5s infinite;
          animation: animatedText2 5s infinite;
  padding-top: 2rem;
  font-family: "Megrim", cursive;
}

.project-container {
  background-color: #212529;
  color: #fff;
  padding: 3rem 2rem !important;
  overflow: hidden !important;
}

.project-container img {
  width: 90%;
  height: 90%;
  border: 5px solid #212529;
  outline: 2px solid #3a3a3a;
  border-radius: 5px;
}

.project-container h1 {
  margin-top: 1rem;
  font-weight: bolder;
  color: #ffd700;
  color: var(--gold);
  font-size: 3rem;
  font-family: "Text Me One", sans-serif;
}

.project-container button a {
  text-decoration: none;
  color: #fff;
}

.project-container .app-desc {
  padding-bottom: 1rem;
  font-size: 1.25rem;
}

.project-container .badge {
  color: #ffd700;
  font-size: 110%;
  font-weight: lighter;
}

.project-container .buttons {
  padding: 2rem 0;
}

.project-container .buttons button {
  margin-right: 1rem;
  font-weight: bolder;
}

.project-container svg {
  color: #e417d9;
  color: var(--pink);
}

/* ------------------------------- contact --------------------------------------- */
.email-container {
  background-color: #212529;
  padding-bottom: 20rem;
}

.email-container * {
  font-family: "Text Me One", sans-serif !important;
  font-size: 1.5rem !important;
}

.email-wrapper {
  background-color: #212529;
  min-height: 100vh;
}

.email-wrapper .social {
  padding: 3rem 0;
}

.email-wrapper .social a {
  -webkit-animation: colorChange2 5s infinite;
          animation: colorChange2 5s infinite;
}

.email-wrapper h1 {
  font-family: "Text Me One", sans-serif !important;
  color: #e417d9 !important;
  color: var(--pink) !important;
  font-size: 3rem !important;
}

.email-wrapper svg {
  font-size: 3rem;
  -webkit-animation: iconColorChange 10s infinite;
          animation: iconColorChange 10s infinite;
}

.email-wrapper a {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bolder;
  letter-spacing: 1.8px;
}

.email-wrapper a:hover {
  color: #ffd700;
  color: var(--gold);
  -webkit-animation: colorChange2 0.5s infinite;
          animation: colorChange2 0.5s infinite;
}

.email-container .btn.btn-warning {
  padding: 0.5rem !important;
  width: 100% !important;
}

/* ------------------------------- animations --------------------------------------- */
@-webkit-keyframes animatedText {
  to {
    background-position: 100%;
  }
}
@keyframes animatedText {
  to {
    background-position: 100%;
  }
}

@-webkit-keyframes animatedText2 {
  0% {
    text-shadow: 2px 2px #e417d9;
    text-shadow: 2px 2px var(--pink);
  }
  50% {
    text-shadow: 2px 2px #ffd700;
    text-shadow: 2px 2px var(--gold);
  }
  100% {
    text-shadow: 2px 2px #e417d9;
    text-shadow: 2px 2px var(--pink);
  }
}

@keyframes animatedText2 {
  0% {
    text-shadow: 2px 2px #e417d9;
    text-shadow: 2px 2px var(--pink);
  }
  50% {
    text-shadow: 2px 2px #ffd700;
    text-shadow: 2px 2px var(--gold);
  }
  100% {
    text-shadow: 2px 2px #e417d9;
    text-shadow: 2px 2px var(--pink);
  }
}

@-webkit-keyframes colorChange {
  0% {
    color: #ffd700;
    color: var(--gold);
  }
  50% {
    color: rgb(0, 0, 0);
  }
  100% {
    color: #ffd700;
    color: var(--gold);
  }
}

@keyframes colorChange {
  0% {
    color: #ffd700;
    color: var(--gold);
  }
  50% {
    color: rgb(0, 0, 0);
  }
  100% {
    color: #ffd700;
    color: var(--gold);
  }
}

@-webkit-keyframes colorChange2 {
  0% {
    text-shadow: 30px 30px 6px #e417da9f;
  }
  50% {
    text-shadow: 30px 30px 3px #e417da46;
  }
  100% {
    text-shadow: 30px 30px 6px #e417da9f;
  }
}

@keyframes colorChange2 {
  0% {
    text-shadow: 30px 30px 6px #e417da9f;
  }
  50% {
    text-shadow: 30px 30px 3px #e417da46;
  }
  100% {
    text-shadow: 30px 30px 6px #e417da9f;
  }
}

@-webkit-keyframes iconColorChange {
  0% {
    color: #e417d9;
    color: var(--pink);
  }
  50% {
    color: #ffd700;
    color: var(--gold);
  }
  100% {
    color: #e417d9;
    color: var(--pink);
  }
}

@keyframes iconColorChange {
  0% {
    color: #e417d9;
    color: var(--pink);
  }
  50% {
    color: #ffd700;
    color: var(--gold);
  }
  100% {
    color: #e417d9;
    color: var(--pink);
  }
}

@-webkit-keyframes glitch {
  2%,
  64% {
    -webkit-transform: translate(2px, 0) skew(0deg);
            transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    -webkit-transform: translate(-2px, 0) skew(0deg);
            transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    -webkit-transform: translate(0, 0) skew(5deg);
            transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitch {
  2%,
  64% {
    -webkit-transform: translate(2px, 0) skew(0deg);
            transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    -webkit-transform: translate(-2px, 0) skew(0deg);
            transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    -webkit-transform: translate(0, 0) skew(5deg);
            transform: translate(0, 0) skew(5deg);
  }
}

@-webkit-keyframes glitchTop {
  2%,
  64% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  4%,
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  62% {
    -webkit-transform: translate(13px, -1px) skew(-13deg);
            transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchTop {
  2%,
  64% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  4%,
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  62% {
    -webkit-transform: translate(13px, -1px) skew(-13deg);
            transform: translate(13px, -1px) skew(-13deg);
  }
}

@-webkit-keyframes glitchBotom {
  2%,
  64% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  4%,
  60% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  62% {
    -webkit-transform: translate(-22px, 5px) skew(21deg);
            transform: translate(-22px, 5px) skew(21deg);
  }
}

@keyframes glitchBotom {
  2%,
  64% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  4%,
  60% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  62% {
    -webkit-transform: translate(-22px, 5px) skew(21deg);
            transform: translate(-22px, 5px) skew(21deg);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

/* ------------------------------- media queries --------------------------------------- */

/* ------------- 595px ------------------- */
@media screen and (max-width: 595px) {
  .main-info h1,
  .main-info h1:before,
  .main-info h1:after {
    -webkit-animation: none;
            animation: none;
  }

  .main-info h1:before,
  .main-info h1:after {
    content: none;
    position: unset;
  }
}

/* ------------- 768px ------------------- */
@media screen and (max-width: 768px) {
  .particles-canvas {
    display: none;
  }

  .main-info h1 {
    font-size: 2.5rem !important;
  }

  .typed-text {
    font-size: 1.5rem !important;
  }

  .btn-main-contact {
    font-size: 1.5rem;
  }

  .caret {
    margin-top: 10rem;
  }

  .jumbo h1 {
    font-size: 4rem;
  }

  .kd-info {
    font-size: 1.1rem;
    margin: 2rem;
    color: #fff;
  }

  .kd {
    margin: 1rem 5rem;
  }
}

/* ------------- 995px ------------------- */
@media screen and (max-width: 995px) {
  .project-container .app-img {
    width: 90vw;
  }
}

/* ------------- 1045px ------------------- */
@media screen and (max-width: 1045px) {
  .main-info h1 {
    font-size: 4.5rem !important;
  }
}

/* ------------- 700px height ------------------- */
@media screen and (max-height: 700px) {
  .main-info h1 {
    margin-top: 3rem;
  }
  .header-wraper {
    height: 140vh;
  }
}

/* @media screen and (max-width: 991px) {
  .navbar-nav li a{
    background-color: #e417d9;
    width: 100%;
  }
} */

